* {
  font-family: var(--font-family);
}

.user-info {
  padding: 8px 8px 0 8px;
  cursor: pointer;
}

.user-info:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.user-info:active {
  background-color: rgba(0, 0, 0, 0.03);
}

.user-info p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-header {
  display: flex;
  align-items: center;
}

.user-name {
  width: 70%;
  padding-left: 4px;
}

.user-options-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
  height: 30px;
}

.kick-button:hover {
  background-color: transparent !important;
}

.kick-button-icon {
  color: rgba(0, 0, 0, 0.6);
}

.user-location {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}

.user-location-icon {
  color: rgba(0, 0, 0, 0.6);
}

