#title-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#title-map {
  width: 50vw;
}

#title-map-alt {
  width: 90vw;
}

#grid-circle {
  animation: grid-animation 10000ms linear 1500ms 1 forwards;
}

#position-circle-purple {
  animation: position-circle-animation 500ms linear 2500ms 1 forwards;
}

#position-circle-red {
  animation: position-circle-animation 500ms linear 1500ms 1 forwards;
}

#position-circle-green {
  animation: position-circle-animation 500ms linear 3000ms 1 forwards;
}

#position-circle-orange {
  animation: position-circle-animation 500ms linear 4000ms 1 forwards;
}

#position-circle-blue-shadow {
  animation: blue-shadow-animation 2000ms linear 0ms infinite forwards;
}

#position-circle-purple-shadow {
  animation: position-circle-animation 500ms linear 2500ms 1 forwards,
    purple-shadow-animation 2000ms linear 3000ms infinite forwards;
}

#position-circle-red-shadow {
  animation: position-circle-animation 500ms linear 1500ms 1 forwards,
    red-shadow-animation 2000ms linear 2000ms infinite forwards;
}

#position-circle-green-shadow {
  animation: position-circle-animation 500ms linear 3000ms 1 forwards,
    green-shadow-animation 2000ms linear 3500ms infinite forwards;
}

#position-circle-orange-shadow {
  animation: position-circle-animation 500ms linear 4000ms 1 forwards,
    orange-shadow-animation 2000ms linear 4500ms infinite forwards;
}

#map-circle0 {
  animation: map0-animation 2000ms linear 1500ms 1 forwards;
}

#map-circle1 {
  animation: map1-animation 2000ms linear 3000ms 1 forwards;
}

#map-circle2 {
  animation: map2-animation 2000ms linear 2000ms 1 forwards;
}

#map-circle3 {
  animation: map3-animation 2000ms linear 3500ms 1 forwards;
}

#map-circle4 {
  animation: map4-animation 2000ms linear 4500ms 1 forwards;
}

@keyframes grid-animation {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(35);
  }
}

@keyframes position-circle-animation {
  from {
    r: 0;
  }
  to {
    r: 10px;
  }
}

@keyframes blue-shadow-animation {
  from {
    fill: rgba(25, 118, 210, 0.7);
    r: 10px;
    offset: 0;
  }
  to {
    fill: rgba(25, 118, 210, 0);
    r: 15px;
    offset: 0.7;
  }
}

@keyframes purple-shadow-animation {
  from {
    fill: rgba(103, 58, 183, 0.7);
    r: 10px;
    offset: 0;
  }
  to {
    fill: rgba(103, 58, 183, 0);
    r: 15px;
    offset: 0.7;
  }
}

@keyframes red-shadow-animation {
  from {
    fill: rgba(244, 67, 54, 0.7);
    r: 10px;
    offset: 0;
  }
  to {
    fill: rgba(244, 67, 54, 0);
    r: 15px;
    offset: 0.7;
  }
}

@keyframes green-shadow-animation {
  from {
    fill: rgba(76, 175, 80, 0.7);
    r: 10px;
    offset: 0;
  }
  to {
    fill: rgba(76, 175, 80, 0);
    r: 15px;
    offset: 0.7;
  }
}

@keyframes orange-shadow-animation {
  from {
    fill: rgba(255, 152, 0, 0.7);
    r: 10px;
    offset: 0;
  }
  to {
    fill: rgba(255, 152, 0, 0);
    r: 15px;
    offset: 0.7;
  }
}

@keyframes map0-animation {
  from {
    r: 10px;
  }
  to {
    r: 20%;
  }
}

@keyframes map1-animation {
  from {
    r: 10px;
  }
  to {
    r: 15%;
  }
}

@keyframes map2-animation {
  from {
    r: 10px;
  }
  to {
    r: 10%;
  }
}

@keyframes map3-animation {
  from {
    r: 10px;
  }
  to {
    r: 18%;
  }
}

@keyframes map4-animation {
  from {
    r: 10px;
  }
  to {
    r: 8%;
  }
}
