#home-body .body-section h1 {
  font-size: 100px;
  color: white;
}

#home-body .body-section h2 {
  font-size: 45px;
  color: white;
}

#home-body .body-section h3 {
  font-size: 30px;
  color: rgba(255, 255, 255, 0.8);
}

#home-body .body-section-alt h1 {
  font-size: 50px;
  color: white;
}

#home-body .body-section-alt h2 {
  font-size: 20px;
  color: white;
}

#home-body .body-section-alt h3 {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8);
}

.body-section {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
}

.body-section-alt {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: 50% 50%;
  grid-template-columns: 100%;
}

.section-left {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-right {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.section-content {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
  height: 50vh;
}

.section-text {
  position: absolute;
}

#text0 {
  opacity: 0;
  animation: text0-animation 20s infinite;
}

#text1 {
  opacity: 0;
  animation: text1-animation 20s infinite;
}

#text2 {
  opacity: 0;
  animation: text2-animation 20s infinite;
}

#text3 {
  opacity: 0;
  animation: text3-animation 20s infinite;
}

@keyframes text0-animation {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  35% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
}

@keyframes text1-animation {
  40% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
}

@keyframes text2-animation {
  60% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
}

@keyframes text3-animation {
  80% {
    opacity: 0;
  }
  85% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.section-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 50vh;
  padding-top: 3vh;
}

.show-content {
  opacity: 1;
  filter: blur(0);
}

.section-circle {
  transform-origin: center;
}

.section-image {
  position: absolute;
  width: min(100vh, 50vw);
  height: min(100vh, 50vw);
}

.section-image-alt {
  position: absolute;
  width: 90vw;
  height: 90vw;
}

.section-image-content {
  height: 100%;
  width: 100%;
}

#section-image-content-2 {
  height: 40%;
}

#home-footer {
  margin-bottom: 5vh;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

#github-icon {
  height: 15px;
}
