.map-wrapper {
  height: calc(100% - 70px);
  width: 100%;
  position: fixed;
}

.map {
  width: 100%;
  height: 100%;
  position: relative;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.recenter-button {
  width: 30px;
  height: 30px;
  border-color: transparent;
  cursor: pointer;
  background-color: white;
}

.leaflet-left .recenter-unpressed {
  padding-top: 70px;
}

.leaflet-left .recenter-pressed {
  padding-top: 70px;
}

.recenter-button:hover {
  background-color: #f4f4f4;
}

.recenter-button-pressed {
  background-color: #f4f4f4;
}

.leaflet-left .recenter-unpressed .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.leaflet-left .recenter-pressed .leaflet-bar {
  border: 2px solid #4285f4;
}

.position-circle-blue {
  width: 25px;
  height: 25px;
  background-color: rgba(25, 118, 210, 1);
  border-radius: 50%;
  animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
  0% {
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(25, 118, 210, 0);
  }
}

.position-circle-purple {
  width: 25px;
  height: 25px;
  background-color: rgba(103, 58, 183, 1);
  border-radius: 50%;
  animation: pulse-purple 2s infinite;
}

@keyframes pulse-purple {
  0% {
    box-shadow: 0 0 0 0 rgba(103, 58, 183, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(103, 58, 183, 0);
  }
}

.position-circle-red {
  width: 25px;
  height: 25px;
  background-color: rgba(244, 67, 54, 1);
  border-radius: 50%;
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(244, 67, 54, 0);
  }
}

.position-circle-green {
  width: 25px;
  height: 25px;
  background-color: rgba(76, 175, 80, 1);
  border-radius: 50%;
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }
}

.position-circle-orange {
  width: 25px;
  height: 25px;
  background-color: rgba(255, 152, 0, 1);
  border-radius: 50%;
  animation: pulse-orange 2s infinite;
}

@keyframes pulse-orange {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 152, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 152, 0, 0);
  }
}

/* .leaflet-tile {
  filter: grayscale(100%) !important;
} */
