.help-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 300px;
  flex-direction: column;
  text-align: center;
}

.help-image {
  width: 100%;
  object-fit: cover;
}
