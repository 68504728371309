#grid-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#grid-background {
  position: absolute;
  width: min(100vh, 50vw);
  height: min(100vh, 50vw);
  z-index: -1;
}

#grid-background-alt {
  position: absolute;
  width: 90vw;
  height: 90vw;
  z-index: -1;
}

#grid-circle {
  transform-origin: center;
}
