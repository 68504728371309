.menu-drawer {
  width: 0vw;
  height: 100vh;
  flex-shrink: 0;
}

.menu-drawer .MuiDrawer-paper {
  width: 20vw;
  overflow: hidden;
}

.menu-drawer-alt {
  width: 0vw;
  height: 100vh;
  flex-shrink: 0;
}

.menu-drawer-alt .MuiDrawer-paper {
  width: 100vw;
  height: 100vh;
}

.drawer-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  min-height: 70px;
  overflow: hidden;
}

.close-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.close-button:hover {
  background-color: white !important;
}

.close-icon {
  color: rgba(0, 0, 0, 0.6);
}

.disconnect-div {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disconnect-button:hover {
  background-color: white !important;
}

.drawer-body {
  overflow-y: auto;
  height: calc(100% - 100px);
}

.drawer-footer {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  overflow: hidden;
}

.link-div {
  width: 60%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px 0 16px;
}

.link-div .MuiButtonBase-root {
  color: rgba(0, 0, 0, 0.6);
  border-color: rgba(0, 0, 0, 0.12);
  text-transform: none;
}

.link-div .MuiButtonBase-root:hover {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: rgba(0, 0, 0, 0.08);
}

.link-button {
  width: 100%;
}

.link-text {
  width: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.online-icon {
  color: rgb(0, 214, 0);
}

.footer-section {
  width: 30%;
  max-width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lock-icon {
  color: rgba(0, 0, 0, 0.6);
}

.lock-button:hover {
  background-color: transparent !important;
}

.closing-timer {
  color: #d32f2f;
}
