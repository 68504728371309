#session {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#disconnected {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.session-main {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
