* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background: var(--color-bg);
  height: 100%;
  width: 100%;
}

.option-dialog .MuiDialog-paper {
  height: 300px;
  width: 400px;
  max-height: 90%;
  max-width: 90%;
}

.option-dialog .MuiDialogContent-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.option-dialog .FormInput {
  width: 80%;
}

.option-dialog p {
  padding-left: 8px;
}
