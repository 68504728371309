.MuiAutocomplete-root {
  width: 90%;
}

#search-autocomplete {
  width: 100%;
  padding: 8px 8px 8px 8px;
  color: white;
}

.search-textfield {
  position: relative;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.15);
  margin-right: 0px;
  margin-left: 0px;
  width: 100%;
  color: white;
}

.search-textfield:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.search-option-container {
  align-items: center;
}

.MuiGrid-root .search-option-item {
  display: flex;
  width: 44px;
}

.locationOnIcon {
  color: rgba(0, 0, 0, 0.6);
}