.appbar {
  min-height: 70px;
  max-height: 70px;
  background: var(--color-directory);
  display: flex;
  align-items: center;
  width: 100%;
}

.search-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 85%;
}

.search-entry {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38%;
}

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
}

.appbar .MuiButtonBase-root {
  color: white;
  border-color: transparent;
}

.appbar .MuiButtonBase-root:hover {
  border-color: rgba(255, 255, 255, 0.15);
}

.MuiGrid-root .search-option-item-text {
  width: calc(100% - 44px);
  word-wrap: break-word;
}

.appbar-icon {
  color: white;
  margin-right: 8px;
  margin-left: 8px;
}

.appbar-seperator-icon {
  color: rgba(255, 255, 255, 0.6);
  margin-left: calc(3.17647058824% - 12px);
  margin-right: calc(3.17647058824% - 12px);
}

.appbar .MuiOutlinedInput-notchedOutline {
  border: 0;
}

.appbar-contents-alt {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.appbar-alt {
  height: 160px;
  background: var(--color-directory);
  display: block;
  align-items: center;
  width: 100%;
  padding-top: 8px;
}

.search-entry-alt {
  display: flex;
  justify-content: center;
  align-items: center;
}

.appbar-alt .MuiButtonBase-root {
  color: white;
  border-color: transparent;
}

.appbar-alt .nav-button-alt .MuiButtonBase-root {
  color: white;
  border-color: transparent;
  height: 57px;
}

.appbar-alt .MuiButtonBase-root:hover {
  border-color: rgba(255, 255, 255, 0.15);
}

.appbar-alt .MuiOutlinedInput-notchedOutline {
  border: 0;
}

.appbar-seperator-icon-alt {
  color: rgba(255, 255, 255, 0.6);
  margin-left: 8px;
}

.mobile-directory-spacing {
  color: transparent;
  padding: 0 32px 0 32px;
}